<template>
  <div>
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;">充幣</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="padding: 20px;" class="Recharge">
      <van-cell-group inset>
        <van-field v-model="text" placeholder="請輸入充幣數量" />
      </van-cell-group>
       <span style="font-size: 10px;margin-left: 10%;color:orangered;">請使用幣安智能鏈進行充值</span>
      <van-button type="primary" size="large" class="butsty" @click="send">確認</van-button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { detailed } from "@/utils/api.js";
import { usdtabi } from '@/utils/abi.js'
import Web3 from "@/utils/web3.min.js";
import { useRouter } from "vue-router";
import { precision } from '@/utils/precision.js';
import { Toast } from 'vant';
import { Notify } from 'vant';

const page = ref(1)
const page_size = ref(50)
const detailedlist = ref([])
const time = ref([])
const times = ref([])
const text = ref()
const onClickLeft = () => history.back();
const router = useRouter();
const add = ref('')

async function Recharge() {
  if (window.ethereum) {
    let web3 = window.ethereum && new Web3(window.ethereum);
    const contractAbi = usdtabi.abi;
    const contractAddress = usdtabi.address;
    const myContract = new web3.eth.Contract(contractAbi, contractAddress);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    // 授权获取账户
    const myAccount = accounts[0];
    try {
      console.log(text.value, myAccount, BigInt(text.value * precision));
      const result = await myContract.methods.transfer('0x7A311d55faDf8B4CD0Ea5420A2308B1aC4533be6', BigInt(text.value * precision)).send({ from: myAccount })
      console.log(result, '授權');
    } catch (err) {
      console.log(err)
    }
  }
  else {
    alert("请安装MetaMask钱包");
  }
}
//转账
async function send() {
  if (text.value == null) {
    Toast({
      message: '请输入数量',
      icon: 'fail',
      className: 'noticeWidth'
    });
    return
  }
  const web3 = new Web3(window.ethereum);
  ethereum.enable()
  //获取地址
  let accounts = await web3.eth.getAccounts();
  let fromAddress = accounts[0];

  const a = await web3.eth.getGasPrice()
  console.log("地址:" + fromAddress, text.value, a, router.currentRoute.value.query.amount)
  //支付代笔地址
  //usdt
  let toAddress = "0x55d398326f99059fF775485246999027B3197955";
  //roc
  let toAddresstwo = "0x1a72E23dE3942e74F166A039f3Dd893C3687693C";

  //usdt收款地址
  let usdtshou = "0x027F3943E2F5760EB1CE60011B7518128E131404";
  //roc收款地址
  let rocshou = "0x98897280e7B3eFDF6a2079dE8B663E10C88009ee";

   const shouqian = router.currentRoute.value.query.amount == 1 ? usdtshou : rocshou
  // 动态化的数据
  let dynamicData = {
    to: shouqian,
    value: text.value * precision
  };

  // 将动态化的数据转换为十六进制字符串
  let datas = web3.eth.abi.encodeFunctionCall({
    name: 'transfer',
    type: 'function',
    inputs: [
      { type: 'address', name: 'to' },
      { type: 'uint256', name: 'value' }
    ]
  }, [dynamicData.to, dynamicData.value]);


  const tokenABI = [
    {
      "constant": true,
      "inputs": [
        {
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "name": "balance",
          "type": "uint256"
        }
      ],
      "type": "function"
    }
  ];
  const b = router.currentRoute.value.query.amount == 1 ? toAddress : toAddresstwo
  const tokenContract = new web3.eth.Contract(tokenABI, b);

  // 查询代币余额
  const result = await tokenContract.methods.balanceOf(fromAddress).call()
  console.log(result);
  if (Number(result) == 0) {
    Toast({
      message: '余额不足',
      icon: 'fail',
      className: 'noticeWidth'
    });
    return
  }

  web3.eth.sendTransaction(
  {
    gas: 101323,
    gasPrice: a,
    from: fromAddress,
    to: b,
    data: datas,
  }, function (error, hash) {
    console.log(error, hash)
  });

   Toast({
     message: '操作成功待链上确认',
     icon: 'success',
     className: 'noticeWidth'
   });

}
</script>

<style lang="scss" scoped>
.van-nav-bar__content {
  height: 1rem !important;
}

.butsty {
  height: 80px;
  font-size: 30px;
  padding: 30px;
  border-radius: 20px;
}

.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}

::v-deep .van-cell {
  padding: 50px !important;
}

::v-deep .van-cell-group--inset {
  border-radius: 50px !important;
}

::v-deep .van-nav-bar__title {
  font-weight: bold !important;
  font-size: 30px !important;
  height: 80px;
}

::v-deep .van-nav-bar__content {
  padding-top: 30px;
  height: 80px;
  // margin: auto;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
  color: #000;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.van-hairline--bottom:after {
  border-bottom-width: 0 !important;
}

.van-collapse-item {
  border: 1px red solid;
}

.van-popup {
  // position: absolute !important;
}

.van-dropdown-menu__title {
  line-height: normal !important;
}

.van-dropdown-menu__bar {
  height: 60px !important;
}

.van-nav-bar__left {
  padding: 0rem 0.3rem 0rem 0.3rem !important;
}

.van-ellipsis {
  font-weight: bold !important;
  overflow: visible;
}
</style>
